<section class="pageheading">
  <h1 class="titlec">APIs</h1>
  </section>




<section id="Apis" class="section" style="background-color: #d3d3d3;">
    <h2 class="title is-1 heading-wide">About this page</h2>
    <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
      <div class="column is-half" fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxFlexAlign="center" class="aboutapi animated" animateOnScroll animationName="fadeIn">
        This section, connects to 3 different API's; Github, Twitter and CORE (The world's largest collection of open access research papers.) Both Twitter & Core Require private API Keys to Authenticate to their API. Using an Azure Cloud Function I add my Tokens to requests and return the results back to the client, this is the best way to avoid exposing private keys to the client side.
    </div>
    </div>
  <h1 class="title is-1 heading-wide">GitHub</h1>
  <div class="container is-widescreen"  [style.margin]="(isHandset$ | async) ? '0 0 0 0' : '0 0 0 30%'">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" style="align-content: center;" >

    <div fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="60%">

      <mat-card class="example-card"
      *ngIf="gitshow"
      style="padding: 2rem;"
      class="fade animated"
      animateOnScroll animationName="fadeIn"
      [@gitstate]="gitstate">


        <mat-card-header>
          <img mat-card-avatar [src]="githubResponse$.avatar_url"  alt="Avatar of githubResponse$.name">
          <mat-card-title>{{githubResponse$.name}}</mat-card-title>
          <mat-card-subtitle>{{githubResponse$.bio}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class='icon-text'>
              <mat-icon class="icon-display" aria-hidden="false" aria-label="people icon">people</mat-icon>
                {{githubResponse$.followers}} followers         ·  following {{githubResponse$.following}}
            </div>
           <div  class='icon-text'>
             <mat-icon class="icon-display" aria-hidden="false" aria-label="Example home icon">web</mat-icon>
                <a rel="nofollow me" href="{{githubResponse$.blog}}">{{githubResponse$.blog}}</a>
          </div>

          <div *ngIf="(githubResponse$.hireable)"  class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="Example home icon">work</mat-icon>
              {{githubResponse$.name}} is looking for work
            </div>

          <div *ngIf="(!githubResponse$.hireable)"  class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="Example home icon">work_off</mat-icon>
             {{githubResponse$.name}} is not looking for work
            </div>

          <div class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="location icon">place</mat-icon>
              {{githubResponse$.location}}
            </div>

          <div  class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="mail icon">book</mat-icon>
            Public Repos: {{githubResponse$.public_repos}}
          </div>

          <div  class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="mail icon">event</mat-icon>
            Joined github on: {{githubResponse$.created_at}}
          </div>



          <div><mat-icon class="icon-display" aria-hidden="false" aria-label="mail icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.5 222.3" class="octicon"><path d="M273.5 26.3a109.77 109.77 0 0 1-32.2 8.8 56.07 56.07 0 0 0 24.7-31 113.39 113.39 0 0 1-35.7 13.6 56.1 56.1 0 0 0-97 38.4 54 54 0 0 0 1.5 12.8A159.68 159.68 0 0 1 19.1 10.3a56.12 56.12 0 0 0 17.4 74.9 56.06 56.06 0 0 1-25.4-7v.7a56.11 56.11 0 0 0 45 55 55.65 55.65 0 0 1-14.8 2 62.39 62.39 0 0 1-10.6-1 56.24 56.24 0 0 0 52.4 39 112.87 112.87 0 0 1-69.7 24 119 119 0 0 1-13.4-.8 158.83 158.83 0 0 0 86 25.2c103.2 0 159.6-85.5 159.6-159.6 0-2.4-.1-4.9-.2-7.3a114.25 114.25 0 0 0 28.1-29.1" fill="currentColor"></path></svg></mat-icon>
            <a rel="nofollow me" href="https://twitter.com/{{githubResponse$.twitter_username}}">{{githubResponse$.twitter_username}}</a>
           </div>

        </mat-card-content>

      <mat-card-actions>
          <a [href]="githubResponse$.html_url" mat-button>Go to github profile</a>
        </mat-card-actions>
      </mat-card>

    </div>
  </div>
</div>
</section>

<section id="Apis" class="section" style="background-color: #15202B;">
  <h1 class="title is-1 heading-wide"  style="color:white;">Twitter</h1>
  <div class="container is-widescreen"  [style.margin]="(isHandset$ | async) ? '0 0 0 0' : '0 0 0 5%'">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" style="align-content: center;" >

    <div fxFlex="85%" fxFlex.xs="100%" fxFlex.sm="85%">

      <div
      *ngIf="BirdAppResponse$"
      class="fade animated"
      animateOnScroll animationName="fadeIn"
      [@gitstate]="gitstate">
      <mat-card class="example-card"
      *ngFor="let tweet of BirdAppResponse$"
      style="margin-top:20px; background-color: #121c25;">


        <mat-card-header>
          <img mat-card-avatar [src]="tweet.user.profile_image_url"  alt="Avatar of tweet.user.name">
          <mat-card-title style="line-height: inherit;color:white;">{{tweet.user.name}} | {{tweet.user.screen_name}}</mat-card-title>
          <mat-card-subtitle style="color:white;">{{tweet.text}}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>

    </div>
  </div>
</div>
</section>

<section id="Apis" class="section" style="background-color: #d3d3d3;">
  <h1 class="title is-1 heading-wide">CORE</h1>
  <div class="container is-widescreen"  [style.margin]="(isHandset$ | async) ? '0 0 0 0' : '0 0 0 5%'">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" style="align-content: center;" >

    <div fxFlex="85%" fxFlex.xs="100%" fxFlex.sm="85%">

      <div
      *ngIf="dataResponse$"
      class="fade animated"
      animateOnScroll animationName="fadeIn"
      [@gitstate]="gitstate">
      <mat-card class="example-card"
      *ngFor="let dataResponse of dataResponse$"
      style="margin-top:20px ">


        <mat-card-header>
          <mat-card-title style="line-height: inherit;">{{dataResponse._source.title}}</mat-card-title>
          <mat-card-subtitle><mat-icon class="icon-display" aria-hidden="false" aria-label="people icon">people</mat-icon>Authors: {{dataResponse._source.authors}}</mat-card-subtitle>
          <mat-card-subtitle **ngIf="dataResponse._source.publisher">Publisher: {{dataResponse._source.publisher}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class='icon-text'>
            <mat-icon class="icon-display" aria-hidden="false" aria-label="location icon">description</mat-icon>
              {{dataResponse._source.description}}
            </div>
            <div  class='icon-text'>
              <mat-icon class="icon-display" aria-hidden="false" aria-label="Example home icon">web</mat-icon>
                 <a rel="nofollow me" href="{{dataResponse._source.urls[0]}}">{{dataResponse._source.urls[0]}}</a>
           </div>

        </mat-card-content>
      </mat-card>
    </div>

    </div>
  </div>
</div>
</section>
