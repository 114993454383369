

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['']">Home</a>
      <a mat-list-item [routerLink]="['about']">About</a>
      <a mat-list-item [routerLink]="['portfolio']">Portfolio</a>
      <a mat-list-item [routerLink]="['api']">APIs</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="margin-bottom:5px;
   top: 0;
    position: sticky;
    z-index: 3;
    background-color: inherit;">
    <mat-toolbar class="navbar-menu" style="box-shadow: 0 3px 8px 0 rgba(0,0,0,.1); z-index: 3;">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">


        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span routerLink="/" style="padding-left:10px; font-size: large;" [style.margin-left]="(isBoth$ | async) ? '8%' : '18%'">Pieter van Zyl</span>
      <span class="fill-space" [style.margin-right]="(isBoth$ | async) ? '10%' : '32%'"   ></span>
      <div *ngIf="!(isHandset$ | async)">
        <a mat-button [routerLink]="['']"><strong>Home</strong></a>
        <a mat-button [routerLink]="['about']"><strong>About</strong></a>
        <a mat-button [routerLink]="['portfolio']"><strong>Portfolio</strong></a>
        <a mat-button [routerLink]="['api']"><strong>APIs</strong></a>

      </div>
    </mat-toolbar>
    </div>

    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>
